import { ref } from "vue";

export const states = {
	INITIAL: "INITIAL",
	LOADING: "LOADING",
	RESULT_PRESENT: "RESULT_PRESENT",
	ERROR: "ERROR",
};

export const state = ref(states.INITIAL);
