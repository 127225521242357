<template>
	<div
		:class="['dropzone', 'column', 'no-wrap', 'items-center', 'justify-center', active ? 'dropzone-active' : '']"
		@dragenter.prevent="toggleActive"
		@dragleave.prevent="toggleActive"
		@drop.prevent="drop"
		@dragover.prevent
		@mouseover="hover"
		@mouseleave="blurDrop"
		ref="dropzoneWrapper"
		@click.self="openSelectFile"
	>
		<input type="file" ref="fileInput" class="hidden" :id="id" :accept="accept" @input="inputFiles" />
		<q-img
			class="q-mt-none q-mb-sm"
			no-spinner
			alt="Drop zone icon"
			src="drop-zone-icon.svg"
			style="height: 134px; width: 140px"
		/>
		<h5 id="dropzone-text" class="q-mt-none q-mb-xl">Datei für Upload per Drag & Drop ablegen</h5>
		<button
			id="selectFile"
			v-if="state !== 'ERROR'"
			class="dropzone-button q-mt-none q-mb-lg"
			@click="fileInput?.click()"
		>
			Datei auswählen
		</button>
	</div>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import { defineProps, defineEmits } from "vue";
import { defineExpose } from "vue";

const props = defineProps({
	modelValue: {
		type: Array,
		default: () => [],
	},
	previews: {
		type: Array,
		default: () => [],
	},
	mode: {
		type: String,
		default: "drop",
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	state: {
		type: String,
	},
	accept: String,
	maxFileSize: {
		type: Number,
		default: 1,
	},
	maxFiles: {
		type: Number,
		default: 5,
	},
	width: [Number, String],
	height: [Number, String],
	imgWidth: [Number, String],
	imgHeight: [Number, String],
	previewWrapperClasses: String,
	showSelectButton: {
		type: Boolean,
		default: true,
	},
	selectFileStrategy: {
		type: String,
		default: "replace",
	},
});
const emit = defineEmits(["drop", "update:modelValue"]);

const fileInput = ref(null);
const files = ref([]);
const previewUrls = ref([]);
const active = ref(false);
const dropzoneWrapper = ref(null);
const id = computed(() => {
	if (props.id) return id;
	return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
});

// Manages input files
const inputFiles = (e) => {
	const allFiles = [...e.target.files].slice(0, props.maxFiles);
	const filesSizesAreValid = allFiles.map((item) => {
		const itemSize = (item.size / 1024 / 1024).toFixed(10);
		return itemSize <= props.maxFileSize;
	});

	const filesTypesAreValid = allFiles.map((item) => {
		if (props.accept) {
			return props.accept === item.type;
		}
		return [];
	});

	if (filesSizesAreValid.some((item) => item !== true)) {
		const largeFiles = allFiles.filter((item) => {
			const itemSize = (item.size / 1024 / 1024).toFixed(10);
			return itemSize > props.maxFileSize;
		});
		if (largeFiles.length !== 0) {
			emit("error", ERROR_MESSAGE_FILE_TOO_BIG);
			e.target.value = null;
			return;
		}
	}

	if (props.accept && filesTypesAreValid.some((item) => item !== true)) {
		const wrongTypeFiles = allFiles.filter((item) => props.accept !== item.type);
		if (wrongTypeFiles.length !== 0) {
			emit("error", ERROR_MESSAGE_WRONG_FILE_TYPE);
			e.target.value = null;
			return;
		}
	}

	if (
		(filesSizesAreValid.every((item) => item === true) &&
			props.accept &&
			filesTypesAreValid.every((item) => item === true)) ||
		filesSizesAreValid.every((item) => item === true)
	) {
		if (props.selectFileStrategy === "replace") {
			files.value = allFiles.map((item) => {
				return {
					file: item,
					id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
				};
			});
		}
		if (props.selectFileStrategy === "merge") {
			files.value = [
				...files.value,
				...allFiles.map((item) => {
					return {
						file: item,
						id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
					};
				}),
			];
		}
	}

	const generatedUrls = [];

	files.value.map((item) => {
		generatedUrls.push({
			src: URL.createObjectURL(item.file),
			name: item.file.name,
			size: item.file.size,
			type: item.file.type,
			isTypeAccepted: props.accept === item.file.type,
			id: item.id,
		});
	});
	previewUrls.value = generatedUrls;
	e.target.value = null;
};

// Toggles active state for dropping files(styles)
const toggleActive = () => {
	if (!props.disabled && props.mode !== "preview") {
		active.value = !active.value;
	}
};

// Handles dropped files and input them
const drop = (e) => {
	toggleActive();
	if (!props.disabled && props.mode !== "preview") {
		const files = {
			target: {
				files: [...e.dataTransfer.files],
			},
		};
		emit("drop", e);
		inputFiles(files);
	}
};

// Hover and blur manager
const hover = () => {
	if (!files.value.length && props.state === "indeterminate") {
		active.value = true;
	}
};
const blurDrop = () => {
	active.value = false;
};

// Opens os selecting file window
const openSelectFile = (e) => {
	if (!props.disabled && props.mode === "drop" && e.target.id === "dropzoneWrapper") {
		fileInput.value.click();
	} else {
		e.preventDefault();
	}
};

// Updates local preview state on previews prop change
watchEffect(() => {
	if (props.previews && props.previews.length) {
		previewUrls.value = props.previews.map((item) => {
			return {
				src: item,
				id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
			};
		});
	}
});

watchEffect(() => {
	if (files.value && files.value.length) {
		emit("update:modelValue", files.value);
	}
});

function triggerFileInputClick() {
	fileInput.value.click();
}

defineExpose({
	triggerFileInputClick,
});

const ERROR_MESSAGE_FILE_TOO_BIG = "Datei ist zu groß (max. 1 MB)";
const ERROR_MESSAGE_WRONG_FILE_TYPE = "Falscher Dateityp (bitte XML-Datei verwenden)";
</script>

<style scoped>
.dropzone {
	border: 2px dashed;
	border-radius: 12px;
}

.dropzone-active {
	background: #f8f8f8;
}
</style>
