<template>
	<q-page class="text-center">
		<h2 class="q-ma-xl" style="font-weight: bold">E-Rechnungs-Validierungs-Service</h2>
		<h4 class="q-mt-xl q-mb-none">Sie haben noch keinen Testaccount?</h4>
		<h5 class="q-mt-md q-mb-xl">Klicken Sie hier, um sich kostenfrei und unverbindlich zu registrieren.</h5>
		<q-btn no-caps class="dropzone-button" href="https://www.profiforms.de/e-rechnung-testzugang-anfordern"
			>Registrieren
		</q-btn>
	</q-page>
</template>
