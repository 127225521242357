<template>
	<div>
		<q-layout>
			<TheHeader @logo-clicked="state = states.INITIAL" />
			<q-page-container>
				<MainContentPage id="main-content" v-if="authQueryParamIsPresent" />
				<NoAuthKeyPage id="no-auth-key-content" v-if="!authQueryParamIsPresent" />
			</q-page-container>
		</q-layout>
	</div>
</template>

<script setup>
import TheHeader from "./components/TheHeader.vue";
import NoAuthKeyPage from "./pages/NoAuthKeyPage.vue";
import MainContentPage from "@/pages/MainContentPage.vue";
import { states, state } from "@/pages/states";

const authQueryParamIsPresent = window.location.search.includes("key");
</script>
