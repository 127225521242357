<template>
	<q-page class="column no-wrap justify-start items-center">
		<h3
			class="text-center q-my-xl col-shrink"
			v-show="state !== states.RESULT_PRESENT"
			style="width: 65%; font-weight: bold"
		>
			E-Rechnung Visualisierung und Validierung
		</h3>
		<h5 class="text-center q-mt-none q-mb-none col-shrink" v-show="state !== states.RESULT_PRESENT" style="width: 65%">
			Wählen Sie die zu validierende Datei aus. Bitte beachten Sie, dass ausschließlich XML-Dateien akzeptiert werden.
		</h5>
		<div id="loading-component" v-if="state === states.LOADING" class="column col justify-center items-center">
			<q-spinner-ball size="8em" color="secondary" class="q-mb-xl" />
			<h5 class="no-margin col-shrink">Validieren ...</h5>
		</div>
		<Dropzone
			style="width: 65%"
			ref="dropZone"
			id="drop-zone"
			class="text-black q-mt-xl q-mb-xl w65 col text-center"
			v-show="state === states.INITIAL"
			v-model="files"
			v-on:error="onDropzoneError"
			accept="text/xml"
			:state="state"
		/>
		<div
			id="error-state-content"
			v-if="state === states.ERROR"
			class="text-black q-mt-xl q-mb-xl w65 col column items-center justify-center"
		>
			<q-img
				class="q-mt-none q-mb-sm"
				no-spinner
				alt="Drop zone icon"
				src="error-icon.svg"
				style="height: 134px; width: 140px"
			/>
			<h5 class="q-mt-none q-mb-xl">Validierung fehlgeschlagen ...</h5>
			<q-btn id="returnToInitialState" class="dropzone-button q-mt-none q-mb-lg" @click="state = states.INITIAL"
				>Erneut versuchen
			</q-btn>
		</div>
		<div
			id="resultBlock"
			v-if="state === states.RESULT_PRESENT"
			class="column col-grow items-end"
			style="background-color: white; width: 100%"
		>
			<q-btn
				id="select-another-invoice"
				class="dropzone-button q-ma-md"
				icon="add_circle"
				label="E-Rechnung"
				@click="selectAnotherInvoice()"
			/>
			<embed id="pdfResult" class="col-grow" style="width: 100%" :src="resultPdfURL" type="application/pdf" />
		</div>
	</q-page>
</template>

<script setup>
import { ref, watch } from "vue";
import axios from "axios";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { useQuasar } from "quasar";
import Dropzone from "./Dropzone.vue";
import { state, states } from "./states";

const files = ref([]);
const resultPdfURL = ref("");
const dropZone = ref(null);
let notificationDismissCb = null;

const $q = useQuasar();

const urlParams = new URLSearchParams(window.location.search);
const authKey = urlParams.get("key");

watch(files, (newFiles) => {
	if (newFiles.length) {
		processInvoice(newFiles[0].file);
	}
});

function switchToInitialState() {
	state.value = states.INITIAL;
	dismissNotification();
}

async function processInvoice(file) {
	switchToInitialState();
	state.value = states.LOADING;
	doSkynetRequest(file).then(processSkynetSuccessResponse).catch(processSkynetErrorResponse);
}

function doSkynetRequest(file) {
	return axios.post("https://api.profiforms-dev.de/pdf/create/xrechnung_to_pdf?invoker.response=single-file", file, {
		headers: {
			"x-api-key": `3873ae10-9b1e-4776-9546-f8d44627922a.${authKey}`,
			"Content-Type": "application/xml",
		},
		responseType: "blob",
	});
}

async function processSkynetSuccessResponse(response) {
	resultPdfURL.value = URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
	const isValid = await isInvoiceValid(resultPdfURL.value);
	state.value = states.RESULT_PRESENT;
	showValidationNotification(isValid ? VALID_INVOICE_NOTIFICATION : INVALID_INVOICE_NOTIFICATION);
}

async function processSkynetErrorResponse(error) {
	dismissNotification();
	if (error.response) {
		setError(await errorCodeToMessage(error.response));
	} else {
		setError(`${ERROR_MESSAGE_NETWORK}: ${error.message}`);
	}
}

async function isInvoiceValid(pdfUrl) {
	const loadingTask = pdfjsLib.getDocument(pdfUrl);
	const pdf = await loadingTask.promise;
	const attachmentsData = await pdf.getAttachments();
	if (attachmentsData) {
		for (const [key, value] of Object.entries(attachmentsData)) {
			if (key === "validation_report.json") {
				const blob = new Blob([value.content]);
				const json_report_url = URL.createObjectURL(blob);
				const response = await fetch(json_report_url);
				return (await response.json()).valid;
			}
		}
	}
}

function selectAnotherInvoice() {
	dropZone.value.triggerFileInputClick();
}

function onDropzoneError(errorMessage) {
	setError(errorMessage);
}

function setError(message) {
	state.value = states.ERROR;
	showErrorNotification(message);
}

async function errorCodeToMessage(errorResponse) {
	const status = errorResponse.status;
	if (status === 400) {
		return await errorResponse.data.text();
	} else if (status === 429) {
		return ERROR_MESSAGE_429;
	} else if (status === 403) {
		return ERROR_MESSAGE_403;
	} else if (status === 500) {
		return ERROR_MESSAGE_500;
	} else {
		return ERROR_MESSAGE_UNKNOWN;
	}
}

function showValidationNotification(validationNotification) {
	showNotificationWithCloseButton(validationNotification);
}

function showErrorNotification(message) {
	showNotificationWithCloseButton({
		attrs: {
			id: "error-notification",
		},
		message: message,
		icon: "error",
		iconColor: "negative",
	});
}

function showNotificationWithCloseButton(notification) {
	showNotification({
		...notification,
		actions: [{ label: "Schließen", color: "black", size: "lg" }],
	});
}

function showNotification(notificationData) {
	dismissNotification();
	notificationDismissCb = $q.notify({
		...notificationData,
		textColor: "black",
		classes: "pf-notification text-h5",
		timeout: 0,
		color: "white",
		attrs: {
			style: "width: 50%",
			...notificationData.attrs,
		},
	});
}

function dismissNotification() {
	if (notificationDismissCb) {
		notificationDismissCb();
		notificationDismissCb = null;
	}
}

watch(state, (newState) => {
	if (newState === states.INITIAL) {
		dismissNotification();
	}
});

const VALID_INVOICE_NOTIFICATION = {
	attrs: {
		id: "valid-invoice-notification",
	},
	message: "E-Rechnung ist valide",
	icon: "check_circle",
	iconColor: "green",
};

const INVALID_INVOICE_NOTIFICATION = {
	attrs: {
		id: "invalid-invoice-notification",
	},
	message: "E-Rechnung ist nicht valide",
	icon: "warning",
	iconColor: "red",
};

const ERROR_MESSAGE_429 = "Zu hohes Anfrageaufkommen";
const ERROR_MESSAGE_403 = "Unautorisierter Zugriff";
const ERROR_MESSAGE_500 = "Interner Serverfehler";
const ERROR_MESSAGE_UNKNOWN = "Unbekannter Fehler";
const ERROR_MESSAGE_NETWORK = "Fehler";
</script>
