<template>
	<q-header elevated class="q-pa-md">
		<q-toolbar>
			<q-img
				id="profiforms-logo"
				@click="emit('logo-clicked')"
				no-spinner
				alt="profiforms logo"
				src="profifroms-logo-negativ-menubar.svg"
				style="height: 52px; width: 240px"
				class="cursor-pointer"
			/>
			<q-toolbar-title />
			<q-btn size="lg" flat no-caps label="Datenschutz" href="https://www.profiforms.de/datenschutz" />
			<q-btn
				size="lg"
				flat
				no-caps
				label="Kontakt"
				href="https://www.profiforms.de/allgemeines-kontaktformular-profiforms"
			/>
		</q-toolbar>
	</q-header>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["logo-clicked"]);
</script>
