import script from "./MainContentPage.vue?vue&type=script&setup=true&lang=js"
export * from "./MainContentPage.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QSpinnerBall from 'quasar/src/components/spinner/QSpinnerBall.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QSpinnerBall,QImg,QBtn});
